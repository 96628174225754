import React, { Component } from 'react'
import { Bounce, Fade } from 'react-reveal'
export default class Slider extends Component {
  render() {
    return (
      <div className="md:mt-30 mt-24 text-center xl:w-full  ">
        <Fade bottom duration={1000}>
          <div
            className="container"
            style={{ maxWidth: '3389px', padding: '0px 0px' }}
          >
            <div className="fn_cs_welcome">
              <Bounce right duration={1000}>
                <h3 className="maintitle">
                  Flare Flushers Now Live on Flare Network
                </h3>
                <a href="/mint">
                  <button className="hbtn hb2 w-full ">
                    <h5>MINT Now</h5>
                  </button>
                </a>
              </Bounce>
            </div>
          </div>
        </Fade>
      </div>
    )
  }
}
