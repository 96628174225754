import { Bounce } from 'react-reveal'

import CollectionSlider from './collectionSlider'
const Collection = () => {
  return (
    <section id="collection">
      <div className="container">
        <div className="fn_cs_title">
          <Bounce right duration={1000}>
            <button className="hbtn hb5 btntitle ">
              <h5 style={{ color: 'black', fontSize: '30px' }}>GALLERY</h5>
            </button>
          </Bounce>
        </div>
      </div>
      <CollectionSlider rtl={true} />
      <CollectionSlider rtl={false} />
      <CollectionSlider rtl={true} />
    </section>
  )
}

export default Collection
