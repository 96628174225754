import { Slider, SliderItem } from '../../component/common/slider/Slider'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import img1 from '../../assets/images/nft/mintslider/1.png'
import img2 from '../../assets/images/nft/mintslider/2.png'
import img3 from '../../assets/images/nft/mintslider/3.png'
import img4 from '../../assets/images/nft/mintslider/4.png'
import img5 from '../../assets/images/nft/mintslider/5.png'
import img6 from '../../assets/images/nft/mintslider/6.png'
import img7 from '../../assets/images/nft/mintslider/7.png'
import img8 from '../../assets/images/nft/mintslider/8.png'
import img9 from '../../assets/images/nft/mintslider/9.png'
import img10 from '../../assets/images/nft/mintslider/10.png'

export default function SliderNFT() {
  const slideImages = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
  ]

  const sliderSettings = {
    dots: true,
    arrows: true,
    autoplay: true,
    speed: 500,
    fade: true,
    autoplaySpeed: 700,
    centerMode: true,
    centerPadding: '0px',
    infinite: true,
    slidesToShow: 1,
    pauseOnHover: true,
    slidesToScroll: 1,
  }

  return (
    <Slider {...sliderSettings}>
      {slideImages?.map((thumb, idx) => (
        <SliderItem key={idx}>
          <img src={thumb} alt="thumb" />
        </SliderItem>
      ))}
    </Slider>
  )
}
