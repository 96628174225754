import { Fade, Bounce } from 'react-reveal'

import AboutUsIMG from '../../assets/images/nft/aboutus1.jpg'
const Collection = () => {
  return (
    <section id="about" className="">
      <div className="container">
        <div className="fn_cs_title ">
          <Bounce left duration={1000}>
            <button className="hbtn hb3 text-black">
              <h5 style={{ color: 'black', fontSize: '30px' }}>ABOUT US</h5>
            </button>
          </Bounce>
        </div>
      </div>
      <Fade bottom duration={1000}>
        <div className="container">
          <div className="fn_row">
            <div className="fn_col2 about_img flex justify-center w-full">
              <img src={AboutUsIMG} alt="" width="1300px" />
            </div>{' '}
            <div className="fn_col2 pt-16">
              <p
                className="text-x1 font-bold text-black text-slate-50"
                style={{ fontFamily: 'Blanket of Snow' }}
              >
                A COLLECTION OF 5000 FLARE FLUSHERS
              </p>
              <p
                className="text-black text-slate-50"
                style={{ fontFamily: 'Blanket of Snow' }}
              >
                Following the successful launch of our Toilet Monsters
                collection on the XRPL network, we are excited to announce our
                transition to the FLARE network! Originally featuring 10,000
                unique and charming monster heads, we have refined our
                collection to 5,000, maintaining the essence of their whimsical
                charm and hand-drawn aesthetic. While 500 Toilet Monsters remain
                minted out on the XRPL, we've made the decision to burn the
                remaining supply to enhance exclusivity and value for our
                collectors. As a token of appreciation to our loyal XRPL NFT
                holders, we are delighted to offer a 1:1 airdrop from our new
                Flash Flushers NFT collection on the FLARE network. This
                transition not only marks a new chapter for our community but
                also reinforces our commitment to innovation and quality in the
                enchanting world of NFTs. Join us as we continue to explore
                creative horizons, promising an even more captivating and
                heartwarming experience with the adorable creatures of our
                collections.
              </p>
              <p
                className="text-x1 font-bold text-black text-slate-50"
                style={{ fontFamily: 'Blanket of Snow' }}
              >
                BENEFITS OF OUR NFTs
              </p>
              <p
                className="font-light text-black text-slate-50"
                style={{ fontFamily: 'Blanket of Snow' }}
              >
                Discover the exclusive benefits of our NFT collection, crafted
                with six distinct levels of rarity: Common, Uncommon, Rare,
                Super Rare, Epic, and Legendary. With a generous reward pool of
                333k FLR, our mint rewards offer substantial prizes across
                different rarities: <br></br>
                <br></br>🌟 Uncommon (1 Star): Featuring 50 NFTs, each rewarding
                1,090 FLR.<br></br> 🌟🌟 Rare (2 Stars): Comprising 25 NFTs,
                with each offering a 2,100 FLR prize. <br></br>🌟🌟🌟 Super Rare
                (3 Stars): A selection of 15 NFTs, each with a 6,500 FLR reward.
                <br></br>🌟🌟🌟🌟 Epic (4 Stars): An exclusive group of 5 NFTs,
                each valued at 12,940 FLR. <br></br>🌟🌟🌟🌟🌟 Legendary (5
                Stars): The pinnacle of rarity with 2 NFTs, each boasting a
                31,900 FLR prize. <br></br>
                <br></br>In addition to these enticing mint rewards, our NFT
                holders are eligible for further benefits: FlushCoin Airdrop:
                Holders of our NFTs will be the proud recipients of our native
                token, FLC, through a strategic airdrop, enriching their digital
                asset portfolio. NFT Staking: Engage in our NFT staking program
                to earn FLC tokens daily. This initiative not only rewards our
                community members but also enhances the value and utility of
                holding our NFTs.
              </p>
            </div>
          </div>
        </div>
      </Fade>
    </section>
  )
}

export default Collection
