import { Bounce, Fade } from 'react-reveal'

import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { Collapse, theme } from 'antd'
const { Panel } = Collapse
const text = `
Discover the whimsical world of Flash flushers, a delightful NFT collection featuring 5000 unique monster heads emerging from toilets in a charming 2D doodle style on FLARE network. With pastel colors and a hand-drawn aesthetic, each endearing Flare Flushers showcases its own distinctive personality, inviting you into a world of enchanting tales and creativity.`
const text1 = `
Flare Flushers will be minting 20th Feb 2024 15:00 UTC. You will be able to mint on our minting dApp using Metamask or Bifrost wallets.
`
const text2 = `
It is amazing & unique artwork designed by SpaceCats Team & it bring in alot of benefits.
`
const text3 = `
1. FLR Rewards: Upto 333,000 FLR rewards for minting rare Flash flusers.

2. FlushCoin (FLC) airdrop to the NFT holders.

3. NFT staking to earn FLC daily.

`
const text4 = `
Mint cost is fixed at 399 FLR per mint.
`

const CustomHeader = (header) => {
  return <div className="panelheader uppercase">{header}</div>
}

const Faq = () => {
  const { token } = theme.useToken()
  const panelStyle = {
    marginBottom: 24,
    background: 'transparent',
    borderRadius: token.borderRadiusLG,
    border: 'none',
  }
  return (
    <section id="faq">
      <div className="container">
        <div className="fn_cs_title">
          <Bounce left duration={1000}>
            <div className="text-center">
              <button className="hbtn hb7">
                <h5 style={{ color: 'black', fontSize: '30px' }}>FAQ</h5>
              </button>
            </div>
          </Bounce>
        </div>
        <p
          className="text-center font-bold text-black text-slate-50"
          style={{ fontFamily: 'Blanket of Snow', fontSize: '28px' }}
        >
          Frequently Asked Questions!
        </p>
      </div>

      <div className="container">
        <Fade duration={2000}>
          <div className="gap-3 sm:flex w-full">
            <Collapse
              accordion
              bordered={false}
              defaultActiveKey={['1']}
              expandIcon={({ isActive }) =>
                !isActive ? (
                  <div className="bg-white p-3 rounded-full">
                    <PlusOutlined
                      style={{ fontSize: '15px', fontWeight: '700' }}
                    />
                  </div>
                ) : (
                  <div className="bg-white p-3 rounded-full">
                    <MinusOutlined
                      style={{ fontSize: '15px', fontWeight: '700' }}
                    />
                  </div>
                )
              }
              expandIconPosition="end"
              style={{
                background: token.colorBgContainer,
                width: '100%',
              }}
            >
              <Panel
                header={CustomHeader('What is Flare Flushers NFT Collection?')}
                key="1"
                style={{ panelStyle, fontFamily: 'Blanket of Snow' }}
                className="font-medium m-0 text-lg"
              >
                <p>{text}</p>
              </Panel>
              <Panel
                header={CustomHeader('How we can buy and invest NFT?')}
                key="2"
                style={{ panelStyle, fontFamily: 'Blanket of Snow' }}
                className="font-medium m-0 text-lg "
              >
                <p>{text1}</p>
              </Panel>
              <Panel
                header={CustomHeader(
                  'Why we should choose Flare Flushers NFT?',
                )}
                key="3"
                style={{ panelStyle, fontFamily: 'Blanket of Snow' }}
                className="font-medium m-0 text-lg "
              >
                <p>{text2}</p>
              </Panel>
              <Panel
                header={CustomHeader(
                  'What are benefits of Flare Flushers NFT?',
                )}
                key="4"
                style={{ panelStyle, fontFamily: 'Blanket of Snow' }}
                className="font-medium m-0 text-lg "
              >
                <p>{text3}</p>
              </Panel>
              <Panel
                header={CustomHeader('What is Mint Cost?')}
                key="5"
                style={{ panelStyle, fontFamily: 'Blanket of Snow' }}
                className="font-medium m-0 text-lg "
              >
                <p>{text4}</p>
              </Panel>
            </Collapse>
          </div>
        </Fade>
      </div>
    </section>
  )
}

export default Faq
