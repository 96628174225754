import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import img1 from '../../assets/images/nft/c1.png'
import img2 from '../../assets/images/nft/c2.png'
import img3 from '../../assets/images/nft/c3.png'
import img4 from '../../assets/images/nft/c4.png'
import img5 from '../../assets/images/nft/c5.png'
import img6 from '../../assets/images/nft/c6.png'
import img7 from '../../assets/images/nft/c7.png'
import img8 from '../../assets/images/nft/c8.png'
import img9 from '../../assets/images/nft/c9.png'
import img10 from '../../assets/images/nft/c10.png'
import img11 from '../../assets/images/nft/c11.png'
import img12 from '../../assets/images/nft/c12.png'
import img13 from '../../assets/images/nft/c13.png'
import img14 from '../../assets/images/nft/c14.png'
import img15 from '../../assets/images/nft/c15.png'

const CollectionSlider = (props) => {
  var rtl = props.rtl
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    rtl: rtl,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 0,
    cssEase: 'linear',

    responsive: [
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 370,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  }
  return (
    <>
      <Slider {...settings} className="my-5 slider">
        <div className="px-1">
          <div className="border-2 border-blue-400 p-2 w-full">
            <img src={img1} className="w-90" alt="nftCollectionSlider" />
          </div>
        </div>
        <div className="px-1">
          <div className="border-2 border-blue-400 p-2 w-full">
            <img src={img2} className="w-90" alt="nftCollectionSlider" />
          </div>
        </div>
        <div className="px-1">
          <div className="border-2 border-blue-400 p-2 w-full">
            <img src={img3} className="w-90" alt="nftCollectionSlider" />
          </div>
        </div>
        <div className="px-1">
          <div className="border-2 border-blue-400 p-2 w-full">
            <img src={img4} className="w-90" alt="nftCollectionSlider" />
          </div>
        </div>
        <div className="px-1">
          <div className="border-2 border-blue-400 p-2 w-full">
            <img src={img5} className="w-90" alt="nftCollectionSlider" />
          </div>
        </div>
        <div className="px-1">
          <div className="border-2 border-blue-400 p-2 w-full">
            <img src={img6} className="w-90" alt="nftCollectionSlider" />
          </div>
        </div>
        <div className="px-1">
          <div className="border-2 border-blue-400 p-2 w-full">
            <img src={img7} className="w-90" alt="nftCollectionSlider" />
          </div>
        </div>
        <div className="px-1">
          <div className="border-2 border-blue-400 p-2 w-full">
            <img src={img8} className="w-90" alt="nftCollectionSlider" />
          </div>
        </div>
        <div className="px-1">
          <div className="border-2 border-blue-400 p-2 w-full">
            <img src={img9} className="w-90" alt="nftCollectionSlider" />
          </div>
        </div>
        <div className="px-1">
          <div className="border-2 border-blue-400 p-2 w-full">
            <img src={img10} className="w-90" alt="nftCollectionSlider" />
          </div>
        </div>
        <div className="px-1">
          <div className="border-2 border-blue-400 p-2 w-full">
            <img src={img11} className="w-90" alt="nftCollectionSlider" />
          </div>
        </div>
        <div className="px-1">
          <div className="border-2 border-blue-400 p-2 w-full">
            <img src={img12} className="w-90" alt="nftCollectionSlider" />
          </div>
        </div>
        <div className="px-1">
          <div className="border-2 border-blue-400 p-2 w-full">
            <img src={img13} className="w-90" alt="nftCollectionSlider" />
          </div>
        </div>
        <div className="px-1">
          <div className="border-2 border-blue-400 p-2 w-full">
            <img src={img14} className="w-90" alt="nftCollectionSlider" />
          </div>
        </div>
        <div className="px-1">
          <div className="border-2 border-blue-400 p-2 w-full">
            <img src={img15} className="w-90" alt="nftCollectionSlider" />
          </div>
        </div>
      </Slider>
    </>
  )
}

export default CollectionSlider
