import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import Countdown from 'react-countdown'
import { ClassicSpinner } from 'react-spinners-kit'
import { useWeb3React } from '@web3-react/core'
import { NotificationManager, NotificationContainer } from 'react-notifications'

import Slider from './slider'
import MINTCONTRACT_ABI from '../../assets/abis/MINTCONTRACT_ABI.json'
import BUNNYTOKEN_ABI from '../../assets/abis/BUNNYTOKEN_ABI.json'
import config, { BurnBunny } from '../../config/config'

const ethers = require('ethers')

const Mint = () => {
  const [publicState, setPublicState] = useState(true)
  const { account } = useWeb3React()
  const [mintPrice, setMintPrice] = useState(399)
  const [whiteListCount, setWhiteListCount] = useState(0)
  const [totalMintedCount, setTotalMintedCount] = useState(0)
  const [mintCount, setMintCount] = useState(1)
  const [mintState, setMintState] = useState(false)

  const Provider = new ethers.providers.Web3Provider(window.ethereum)
  const Signer = Provider.getSigner()

  const NFTMintContract = new ethers.Contract(
    config.MINTCONTRACT_ADDRESS,
    MINTCONTRACT_ABI,
    Signer,
  )

  const BUNNYTOKENContract = new ethers.Contract(
    config.BUNNYTOKEN_ADDRESS,
    BUNNYTOKEN_ABI,
    Signer,
  )

  const controlMintCount = (state) => {
    if (state === true) {
      mintCount >= 1 ? setMintCount(5) : setMintCount(mintCount + 1)
    } else {
      mintCount <= 1 ? setMintCount(5) : setMintCount(mintCount - 1)
    }
  }

  const countDownComplete = () => {
    setPublicState(true)
    setMintPrice(0)
  }

  const mintFunc = async () => {
    if (account) {
      setMintState(true)
      if (publicState) {
        try {
          const mintTx = await NFTMintContract.mint(mintCount, {
            gasLimit: config.totalGas * mintCount,
            value: ethers.utils.parseEther((mintPrice * mintCount).toString()),
          })
          await mintTx.wait()
          NotificationManager.success('Minted successfully!')
          setMintState(false)
          isWhiteList()
          totalSupply()
        } catch (error) {
          setMintState(false)
        }
      } else {
        try {
          const tx = await BUNNYTOKENContract.approve(
            config.MINTCONTRACT_ADDRESS,
            ethers.utils.parseEther(config.BurnBunny.toString()),
            {
              gasLimit: 300000,
            },
          )
          await tx.wait()
          const mintTx = await NFTMintContract.mintWhiteList(
            config.whiteListMintCount,
            {
              gasLimit: config.totalGas,
              value: ethers.utils.parseEther(mintPrice.toString()),
            },
          )
          await mintTx.wait()
          NotificationManager.success('Minted successfully!')
          setMintState(false)
          isWhiteList()
          totalSupply()
        } catch (error) {
          setMintState(false)
        }
      }
    } else {
      NotificationManager.info('Please Connect wallet')
    }
  }

  const totalSupply = async () => {
    let balance = 0
    if (account !== undefined) {
      balance = await NFTMintContract.totalSupply()
      const count = Number(balance.toString())
      console.log(count)
      setTotalMintedCount(count)
      if (count >= config.MaxMintCount) {
      }
    }
  }

  const isWhiteList = async () => {
    if (account) {
      try {
        let mintCountBigNumber = await NFTMintContract.getMintCount(account)
        let mintCount = mintCountBigNumber.toNumber() // Convert BigNumber to a standard number
        console.log(`Mint count for account ${account}:`, mintCount)
        setWhiteListCount(mintCount)
      } catch (error) {
        console.error('Error fetching mint count:', error)
      }
    } else {
      console.log('No account found.')
    }
  }

  useEffect(() => {
    if (account) {
      totalSupply()
      isWhiteList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.5 }}
    >
      <div className="container relative small">
        <h3
          className="fn__maintitle mt-32 my-10 text-5xl text-center text-black text-Nunito"
          data-align="left"
        >
          Flare Flushers
        </h3>
        <div className="nft_single_img">
          <div className="item">
            <div className="item_in">
              <Slider />
            </div>
          </div>
        </div>
        <div className="desc"></div>
        <div className="metaverse_fn_mintbox">
          <div className="mint_title">
            <span>{!publicState ? 'Whitelist' : 'Public'} Mint is Live</span>
          </div>

          <div className="mint_body">
            <div className="mint_content">
              <div className="mint_list">
                <ul>
                  <li>
                    <div className="item">
                      <h7>Mint Cost</h7>
                      <h3> 399 FLR</h3>
                    </div>
                  </li>
                  {publicState && (
                    <li>
                      <div className="item">
                        <h7>Quantity</h7>
                        <div className="flex gap-3 justify-center text-2xl w-full">
                          <span
                            className="cursor-pointer decrease"
                            onClick={() => controlMintCount(false)}
                          >
                            -
                          </span>
                          <span className="summ" data-price="2.25">
                            {mintCount}
                          </span>
                          <span
                            className="cursor-pointer increase"
                            onClick={() => controlMintCount(true)}
                          >
                            +
                          </span>
                        </div>
                      </div>
                    </li>
                  )}
                  <li>
                    <div className="item">
                      <h7>Minted</h7>
                      <h3>{totalMintedCount} / 5000</h3>
                    </div>
                  </li>
                  <li>
                    <div className="item">
                      <h7>Total Price</h7>
                      <h3>
                        <span className="total_price">
                          {mintCount * BurnBunny}
                        </span>{' '}
                        FLR
                      </h3>
                    </div>
                  </li>
                </ul>
              </div>
              {totalMintedCount !== config.MaxMintCounts && (
                <div className="mint_desc">
                  <button
                    className="metaverse_fn_button"
                    onClick={() => mintFunc()}
                    disabled={false}
                  >
                    <span className="text">Mint NOW </span>
                  </button>
                </div>
              )}
              {whiteListCount === 3 && (
                <div className="mint_desc">
                  <h1 className="font-bold text-xl">No Mint limit</h1>
                </div>
              )}
              {totalMintedCount === config.MaxMintCounts && (
                <div className="mint_desc">
                  <h1 className="font-bold ml-5 mt-2 text-md text-black">
                    Flare Flushers SOLD OUT!!!
                  </h1>
                </div>
              )}{' '}
            </div>

            <div className="mint_checked">{publicState && <p></p>}</div>
          </div>
        </div>
        {mintState && (
          <div className="metaverse_fn_preloader opacity-90 rounded-2xl">
            <ClassicSpinner size={40} />
            <h1 className="font-bold ml-5 mt-2 text-md text-white">
              {' '}
              Minting Flare Flushers...
            </h1>
          </div>
        )}
      </div>
      <NotificationContainer />
    </motion.section>
  )
}

export default Mint
