import { Bounce, Fade } from 'react-reveal'
import img1 from '../../assets/images/nft/r1.png'
import img2 from '../../assets/images/nft/r2.png'
import img3 from '../../assets/images/nft/r3.png'
import img4 from '../../assets/images/nft/r4.png'
import Slider from 'react-slick'
import bgImage from '../../assets/images/nft/bg.png'

const RoadMap = () => {
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    // autoplay: true,
    slidesToScroll: 1,
    speed: 50,
    autoplaySpeed: 1,
    cssEase: 'linear',

    responsive: [
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 370,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  }
  return (
    <section
      id="roadmap"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div className="container">
        <Bounce right duration={1000}>
          <div className="fn_cs_title">
            <div className="text-center">
              <button className="hbtn hb4">
                <h5 style={{ color: 'black', fontSize: '30px' }}>ROADMAP</h5>
              </button>
            </div>
          </div>
        </Bounce>
        <p
          className="text-center font-bold text-black text-slate-50"
          style={{ fontFamily: 'Blanket of Snow', fontSize: '28px' }}
        >
          5000 Flare Flushers on Flare network.
        </p>
      </div>
      <Fade duration={1000}>
        <div className="fn_cs_roadmap">
          <div className="container">
            <div className="roadmap_bottom">
              <div className="swiper-container">
                <div className="swiper-wrapper">
                  <Slider {...settings} className="my-10 slider">
                    <div className="px-5">
                      <div className="swiper-slide">
                        <div
                          className="item"
                          style={{ border: '2px solid black' }}
                        >
                          <div className="item_in">
                            <div className="phase">
                              <h3
                                style={{
                                  color: 'black',
                                  fontFamily: 'Blanket of Snow',
                                }}
                              >
                                Phase 1
                              </h3>
                            </div>
                            <div className="item_content">
                              <div className="info_img">
                                <img
                                  src={img1}
                                  alt=""
                                  style={{ borderRadius: '10%' }}
                                />
                              </div>
                              <div className="info_desc">
                                <h3
                                  className="fn_title"
                                  style={{
                                    color: 'black',
                                    fontFamily: 'Blanket of Snow',
                                    fontSize: '22px',
                                    fontWeight: 'bold',
                                    textShadow:
                                      '0 0 10px #F4A4C7, 0 0 20px #F4A4C7, 0 0 30px #F4A4C7, 0 0 40px #F4A4C7',
                                  }}
                                >
                                  Artwork Development & Team Building
                                </h3>
                                <p
                                  className="fn_desc"
                                  style={{
                                    color: 'black',
                                    fontFamily: 'Blanket of Snow',
                                    textShadow:
                                      '0 0 10px #F4A4C7, 0 0 20px #F4A4C7, 0 0 30px #F4A4C7, 0 0 40px #F4A4C7',
                                  }}
                                >
                                  - Artwork Design & Supply.
                                </p>
                                <p
                                  className="fn_desc"
                                  style={{
                                    color: 'black',
                                    fontFamily: 'Blanket of Snow',
                                    textShadow:
                                      '0 0 10px #F4A4C7, 0 0 20px #F4A4C7, 0 0 30px #F4A4C7, 0 0 40px #F4A4C7',
                                  }}
                                >
                                  - Team Building.
                                </p>
                                <p
                                  className="fn_desc"
                                  style={{
                                    color: 'black',
                                    fontFamily: 'Blanket of Snow',
                                    textShadow:
                                      '0 0 10px #F4A4C7, 0 0 20px #F4A4C7, 0 0 30px #F4A4C7, 0 0 40px #F4A4C7',
                                  }}
                                >
                                  - Social Media Engagement.
                                </p>
                                <div className="read"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-5">
                      <div className="swiper-slide">
                        <div
                          className="item"
                          style={{ border: '2px solid black' }}
                        >
                          <div className="item_in">
                            <div className="phase">
                              <h3
                                style={{
                                  color: 'black',
                                  fontFamily: 'Blanket of Snow',
                                }}
                              >
                                Phase 2
                              </h3>
                            </div>
                            <div className="item_content">
                              <div className="info_img">
                                <img
                                  src={img3}
                                  alt=""
                                  style={{ borderRadius: '10%' }}
                                />
                              </div>
                              <div className="info_desc">
                                <h3
                                  className="fn_title"
                                  style={{
                                    color: 'black',
                                    fontFamily: 'Blanket of Snow',
                                    fontSize: '22px',
                                    fontWeight: 'bold',
                                    textShadow:
                                      '0 0 10px #F4A4C7, 0 0 20px #F4A4C7, 0 0 30px #F4A4C7, 0 0 40px #F4A4C7',
                                  }}
                                >
                                  NFT Collection Launch
                                </h3>
                                <p
                                  className="fn_desc"
                                  style={{
                                    color: 'black',
                                    fontFamily: 'Blanket of Snow',
                                    textShadow:
                                      '0 0 10px #F4A4C7, 0 0 20px #F4A4C7, 0 0 30px #F4A4C7, 0 0 40px #F4A4C7',
                                  }}
                                >
                                  - Smart contract & dApp coding & testing.
                                </p>
                                <p
                                  className="fn_desc"
                                  style={{
                                    color: 'black',
                                    fontFamily: 'Blanket of Snow',
                                    textShadow:
                                      '0 0 10px #F4A4C7, 0 0 20px #F4A4C7, 0 0 30px #F4A4C7, 0 0 40px #F4A4C7',
                                  }}
                                >
                                  - Launch our NFT Minting.
                                </p>
                                <p
                                  className="fn_desc"
                                  style={{
                                    color: 'black',
                                    fontFamily: 'Blanket of Snow',
                                    textShadow:
                                      '0 0 10px #F4A4C7, 0 0 20px #F4A4C7, 0 0 30px #F4A4C7, 0 0 40px #F4A4C7',
                                  }}
                                >
                                  - Introduce FLC token & Airdrop.
                                </p>
                                <p
                                  className="fn_desc"
                                  style={{
                                    color: 'black',
                                    fontFamily: 'Blanket of Snow',
                                    textShadow:
                                      '0 0 10px #F4A4C7, 0 0 20px #F4A4C7, 0 0 30px #F4A4C7, 0 0 40px #F4A4C7',
                                  }}
                                >
                                  - NFT staking launch.
                                </p>
                                <div className="read"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{' '}
                    <div className="px-5">
                      <div className="swiper-slide">
                        <div
                          className="item"
                          style={{ border: '2px solid black' }}
                        >
                          <div className="item_in">
                            <div className="phase">
                              <h3
                                style={{
                                  color: 'black',
                                  fontFamily: 'Blanket of Snow',
                                }}
                              >
                                Phase 3
                              </h3>
                            </div>
                            <div className="item_content">
                              <div className="info_img">
                                <img
                                  src={img4}
                                  alt=""
                                  style={{ borderRadius: '10%' }}
                                />
                              </div>
                              <div className="info_desc">
                                <h3
                                  className="fn_title"
                                  style={{
                                    color: 'black',
                                    fontFamily: 'Blanket of Snow',
                                    fontSize: '22px',
                                    fontWeight: 'bold',
                                    textShadow:
                                      '0 0 10px #F4A4C7, 0 0 20px #F4A4C7, 0 0 30px #F4A4C7, 0 0 40px #F4A4C7',
                                  }}
                                >
                                  Community Development & Support
                                </h3>
                                <p
                                  className="fn_desc"
                                  style={{
                                    color: 'black',
                                    fontFamily: 'Blanket of Snow',
                                    textShadow:
                                      '0 0 10px #F4A4C7, 0 0 20px #F4A4C7, 0 0 30px #F4A4C7, 0 0 40px #F4A4C7',
                                  }}
                                >
                                  - Mint Rewards & Giveaways
                                </p>
                                <p
                                  className="fn_desc"
                                  style={{
                                    color: 'black',
                                    fontFamily: 'Blanket of Snow',
                                    textShadow:
                                      '0 0 10px #F4A4C7, 0 0 20px #F4A4C7, 0 0 30px #F4A4C7, 0 0 40px #F4A4C7',
                                  }}
                                >
                                  - Continued Community Development.
                                </p>
                                <p
                                  className="fn_desc"
                                  style={{
                                    color: 'black',
                                    fontFamily: 'Blanket of Snow',
                                    textShadow:
                                      '0 0 10px #F4A4C7, 0 0 20px #F4A4C7, 0 0 30px #F4A4C7, 0 0 40px #F4A4C7',
                                  }}
                                >
                                  - Community Interaction.
                                </p>
                                <div className="read"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{' '}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </section>
  )
}

export default RoadMap
