import { Bounce, Zoom } from 'react-reveal'

import founderIMG from '../../assets/images/nft/member1.png'
import artistIMG from '../../assets/images/nft/member2.png'
import devIMG from '../../assets/images/nft/member3.png'
import bgImage from '../../assets/images/nft/team_bg.png'
const Team = () => {
  return (
    <section
      id="roadmap"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div className="container">
        <Bounce left duration={1000}>
          <div className="fn_cs_title">
            <button className="hbtn hb6">
              <h5 style={{ color: 'black', fontSize: '30px' }}>OUR TEAM</h5>
            </button>
          </div>
          <p
            className="text-center font-bold text-black text-slate-50"
            style={{ fontFamily: 'Blanket of Snow', fontSize: '28px' }}
          >
            The Toilet Monsters project is run by 3 memebers. NFT designed by
            SpaceCats Team. We have learnt a lot during the process and we truly
            believe we have created something special for the Flare network
            after XRP experience.
          </p>
        </Bounce>
      </div>

      <div className="container">
        <div className="fn_cs_team">
          <ul className="justify-center team_list">
            <Zoom bottom duration={1000}>
              <li className="team_item">
                <div className="item">
                  <div
                    className="item_in"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '20%',
                    }}
                  >
                    <div className="image">
                      <img src={founderIMG} alt="" />
                    </div>
                    <div className="title_holder">
                      <h4 className="occ">
                        <span>Monster Head</span>
                      </h4>
                      <div className="details">
                        <h3 className="name">Founder</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li className="team_item">
                <div className="item">
                  <div className="item_in">
                    <div className="image">
                      <img src={artistIMG} alt="" />
                    </div>
                    <div className="title_holder">
                      <h4 className="occ">
                        <span>Monster Pop</span>
                      </h4>
                      <div className="details">
                        <h3 className="name">Artist</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li className="team_item">
                <div className="item">
                  <div className="item_in">
                    <div className="image">
                      <img src={devIMG} alt="" />
                    </div>
                    <div className="title_holder">
                      <h4 className="occ">
                        <span>Sadhellkill</span>
                      </h4>
                      <div className="details">
                        <h3 className="name">Lead Dev</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </Zoom>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Team
